@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

/* center horizontal & vertical */
.center_perfect {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.center_left {
  display: flex;
  justify-content: left;
  align-items: center;
}

/* flex wrap */
.wrap {
  flex-wrap: wrap;
}

/* justify content */
.justify_center {
  justify-content: center;
}

.justify_between {
  justify-content: space-between;
}

.justify_around {
  justify-content: space-around;
}

.justify_start {
  justify-content: flex-start;
}

.justify_end {
  justify-content: flex-end;
}

/* gap horizontal */
.gap_0 {
  gap: 0px;
}

.gap_5 {
  gap: 5px;
}

.gap_10 {
  gap: 10px;
}

.gap_15 {
  gap: 15px;
}

.gap_20 {
  gap: 20px;
}

.gap_30 {
  gap: 30px;
}

.gap_40 {
  gap: 40px;
}

/* gap horizontal & vertical */
.gap_5_5 {
  gap: 5px 5px;
}

.gap_10_10 {
  gap: 10px 10px;
}

.gap_15_15 {
  gap: 15px 15px;
}

.gap_20_20 {
  gap: 20px 20px;
}

/* align items */
.align_center {
  align-items: center;
}

.align_start {
  align-items: start;
}

.align_end {
  align-items: end;
}

/* width */
.w_full {
  width: 100%;
}

.w_screen {
  width: 100vw;
}

.w_auto {
  width: auto;
}

.w_fit {
  width: fit-content;
}

.w_a4 {
  width: 210mm;
}

.w_5 {
  width: 5px;
}

.w_10 {
  width: 10px;
}

.w_15 {
  width: 15px;
}

.w_20 {
  width: 20px;
}

.w_25 {
  width: 25px;
}

.w_30 {
  width: 30px;
}

.w_35 {
  width: 35px;
}

.w_40 {
  width: 40px;
}

.w_50 {
  width: 50px;
}

.w_75 {
  width: 75px;
}

.w_80 {
  width: 80px;
}

.w_100 {
  width: 100px;
}

.w_130 {
  width: 130px;
}

.w_150 {
  width: 150px;
}

.w_175 {
  width: 175px;
}

.w_200 {
  width: 200px;
}

.w_220 {
  width: 220px;
}

.w_250 {
  width: 250px;
}

.w_300 {
  width: 300px;
}

.w_350 {
  width: 350px;
}

.w_400 {
  width: 400px;
}

.w_500 {
  width: 500px;
}

.w_700 {
  width: 700px;
}

.w_900 {
  width: 900px;
}

.w_90_percent {
  width: 90%;
}

.w_80_percent {
  width: 80%;
}

.w_70_percent {
  width: 70%;
}

.w_60_percent {
  width: 60%;
}

.w_50_percent {
  width: 50%;
}

.w_40_percent {
  width: 40%;
}

.w_30_percent {
  width: 30%;
}

.w_20_percent {
  width: 20%;
}

.w_10_percent {
  width: 10%;
}

/* width max */

.max_w_80 {
  max-width: 80px;
}

.max_w_150 {
  max-width: 150px;
}

.max_w_160 {
  max-width: 160px;
}

.max_w_200 {
  max-width: 200px;
}

.max_w_240 {
  max-width: 240px;
}

.max_w_250 {
  max-width: 250px;
}

.max_w_300 {
  max-width: 300px;
}

.max_w_470 {
  max-width: 470px;
}

.max_w_500 {
  max-width: 500px;
}

.max_w_a4 {
  max-width: 210mm;
}

/* height max */
.max_h_10 {
  max-height: 10px;
}

.max_h_20 {
  max-height: 20px;
}

.max_h_100 {
  max-height: 100px;
}

.max_h_150 {
  max-height: 150px;
}

.max_h_200 {
  max-height: 200px;
}

.max_h_300 {
  max-height: 300px;
}

.max_h_500 {
  max-height: 500px;
}

.max_h_a4 {
  max-height: 297mm;
}

/* min weight */
.min_w_80 {
  min-width: 80px;
}

.min_w_150 {
  min-width: 150px;
}

.min_w_160 {
  min-width: 160px;
}

.min_w_200 {
  min-width: 200px;
}

.min_w_240 {
  min-width: 240px;
}

.min_w_250 {
  min-width: 250px;
}

.min_w_300 {
  min-width: 300px;
}

.min_w_470 {
  min-width: 470px;
}

.min_w_500 {
  min-width: 500px;
}

.min_w_a4 {
  min-width: 210mm;
}

/* height */
.h_full {
  height: 100%;
}

.h_screen {
  height: 100vh;
}

.h_auto {
  height: auto;
}

.h_0 {
  height: 0px;
}

.h_10 {
  height: 10px;
}

.h_15 {
  height: 15px;
}

.h_20 {
  height: 20px;
}

.h_25 {
  height: 25px;
}

.h_30 {
  height: 30px;
}

.h_35 {
  height: 35px;
}

.h_40 {
  height: 40px;
}

.h_45 {
  height: 45px;
}

.h_50 {
  height: 50px;
}

.h_75 {
  height: 75px;
}

.h_100 {
  height: 100px;
}

.h_125 {
  height: 125px;
}

.h_125 {
  height: 125px;
}

.h_150 {
  height: 150px;
}

.h_200 {
  height: 200px;
}

.h_225 {
  height: 225px;
}

.h_250 {
  height: 250px;
}

.h_300 {
  height: 300px;
}

.h_350 {
  height: 350px;
}

.h_400 {
  height: 400px;
}

.h_500 {
  height: 500px;
}

.h_90_percent {
  height: 90%;
}

.h_80_percent {
  height: 80%;
}

.h_70_percent {
  height: 70%;
}

.h_60_percent {
  height: 60%;
}

.h_50_percent {
  height: 50%;
}

.h_40_percent {
  height: 40%;
}

.h_30_percent {
  height: 30%;
}

.h_20_percent {
  height: 20%;
}

.h_10_percent {
  height: 10%;
}

.h_vh {
  height: 100vh;
}

/* min height */
.min_h_10 {
  min-height: 10px;
}

.min_h_15 {
  min-height: 15px;
}

.min_h_20 {
  min-height: 20px;
}

.min_h_25 {
  min-height: 25px;
}

.min_h_30 {
  min-height: 30px;
}

.min_h_35 {
  min-height: 35px;
}

.min_h_40 {
  min-height: 40px;
}

.min_h_50 {
  min-height: 50px;
}

.min_h_75 {
  min-height: 75px;
}

.min_h_100 {
  min-height: 100px;
}

.min_h_150 {
  min-height: 150px;
}

.min_h_200 {
  min-height: 200px;
}

.min_h_300 {
  min-height: 300px;
}

.min_h_400 {
  min-height: 400px;
}

.min_h_500 {
  min-height: 500px;
}

/* display */
.flex {
  display: flex;
}

.grid {
  display: grid;
}

.grid_child {
  display: inline-grid;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

/* flex direction */
.flex_col {
  flex-direction: column;
}

.flex_row {
  flex-direction: row;
}

/* font size */
.font_10 {
  font-size: 0.625rem;
}

.font_11 {
  font-size: 0.6875rem;
}

.font_12 {
  font-size: 0.75rem;
}

.font_14 {
  font-size: 0.875rem;
}

.font_16 {
  font-size: 1rem;
}

.font_18 {
  font-size: 1.125rem;
}

.font_20 {
  font-size: 1.25rem;
}

.font_24 {
  font-size: 1.5rem;
}

.font_30 {
  font-size: 1.875rem;
}

/* font style */
.bold {
  font-weight: bold;
}

.hover_bold:hover {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.border_none {
  border: none;
}

/* border */
.border_dash {
  border: 2px dashed #ccc;
}

.border {
  border: 1px solid;
}

.border_grey {
  border: 1px solid #b4b6b8;
}

.border_blue {
  border: 2px solid #2c65a9;
}

.border_darkBlue {
  border: 2px solid #006aba;
}

.border_top {
  border-top: 1px solid;
}

.border_top_grey {
  border-top: 1px solid #b4b6b8;
}

.border_right {
  border-right: 1px solid;
}

.border_left {
  border-left: 1px solid;
}

.border_bottom {
  border-bottom: 1px solid;
}

.bold_border_grey {
  border: 3px solid #757677;
}

/* text color */
.text_black {
  color: #000000;
}

.text_white {
  color: #ffffff;
}

.text_blue {
  color: #0ca5eb;
}

.text_darkBlue {
  color: #006aba;
}

.text_orange {
  color: #c66e10;
}

.text_grey {
  color: #3c3c3c;
}

/* underline text */
.underline {
  text-decoration: underline;
}

.hover_underline:hover {
  text-decoration: underline;
}

/* hover size */
.hover_bigger:hover {
  /* zoom: 1.1; */
  transform: scale(1.1);
}

/* hover text */
.hover_text_white:hover {
  color: #ffffff;
}

.hover_text_black:hover {
  color: #000000;
}

/* hover background color */
/* blue */
.hover_lightBlue:hover {
  background: #ccecff;
}

.hover_blue:hover {
  background: #0ca5eb;
}

.hover_darkBlue:hover {
  background: #3278b4;
}

/* green */
.hover_lightGreen:hover {
  background: #a7ce3d;
}

.hover_green:hover {
  background: #76b343;
}

.hover_darkGreen:hover {
  background: #62903d;
}

/* red */
.hover_lightRed:hover {
  background: #f36271;
}

.hover_red:hover {
  background: #ca0b00;
}

.hover_darkRed:hover {
  background: #ab0000;
}

.hover_lightGrey:hover {
  background: #f4f4f4;
}

/* hover border */
.hover_border:hover {
  border: 1px solid;
}

/* background color */

/* white */
.bg_white {
  background: #ffffff;
}

/* blue */
.bg_lightBlue {
  background: #ccecff;
}

.bg_mediumBlue {
  background: #c0e4ff;
}

.bg_blue {
  background: #0ca5eb;
}

.bg_darkBlue {
  background: #006aba;
}

/* green */
.bg_lightGreen {
  background: #a7ce3d;
}

.bg_green {
  background: #76b343;
}

.bg_darkGreen {
  background: #62903d;
}

/* grey */
.bg_lightGrey {
  background: #e5eefdff;
}

.bg_grey {
  background: #c4c4c4;
}

.bg_darkGrey {
  background: #606f8dff;
}

/* red */
.bg_lightRed {
  background: #f36271;
}

.background_red {
  background: #ca0b00;
}

.bg_darkRed {
  background: #ab0000;
}

/* yellow */
.bg_lightYellow {
  background: #ffec9a;
}

.bg_yellow {
  background: #f8e518;
}

.bg_darkYellow {
  background: #e3b709;
}

.bg_black {
  background: #000000;
}

/* position */
.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.right {
  right: 0px;
}

.right_10 {
  right: 10px;
}

.right_20 {
  right: 10px;
}

.right_30 {
  right: 10px;
}

.top {
  top: 0px;
}

.top_10 {
  top: 10px;
}

.left_10 {
  left: 10px;
}

.left {
  left: 0px;
}

.bottom_10 {
  bottom: 10px;
}

.bottom {
  bottom: 0px;
}

/* margin y */
.margin_y_2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.margin_y_5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin_y_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin_y_15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin_y_20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin_y_25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin_y_30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* margin x */
.margin_x_2 {
  margin-left: 2px;
  margin-right: 2px;
}

.margin_x_5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin_x_10 {
  margin-left: 10px;
  margin-right: 10px;
}

.margin_x_15 {
  margin-left: 15px;
  margin-right: 15px;
}

.margin_x_20 {
  margin-left: 20px;
  margin-right: 20px;
}

.margin_x_25 {
  margin-left: 25px;
  margin-right: 25px;
}

.margin_x_30 {
  margin-left: 30px;
  margin-right: 30px;
}

/* margin */
.margin_auto {
  margin: auto;
}

.margin_0 {
  margin: 0;
}

.margin_5 {
  margin: 5px;
}

.margin_10 {
  margin: 10px;
}

.margin_15 {
  margin: 15px;
}

.margin_20 {
  margin: 20px;
}

.margin_25 {
  margin: 20px;
}

.margin_30 {
  margin: 30px;
}

.margin_100 {
  margin: 100px;
}

/* margin top */
.marginTop_5 {
  margin-top: 5px;
}

.marginTop_10 {
  margin-top: 10px;
}

.marginTop_15 {
  margin-top: 15px;
}

.marginTop_20 {
  margin-top: 20px;
}

.marginTop_25 {
  margin-top: 25px;
}

.marginTop_30 {
  margin-top: 30px;
}

.marginTop_50 {
  margin-top: 50px;
}

/* margin right */
.marginRight_0 {
  margin-right: 0px;
}

.marginRight_5 {
  margin-right: 5px;
}

.marginRight_10 {
  margin-right: 10px;
}

.marginRight_15 {
  margin-right: 15px;
}

.marginRight_20 {
  margin-right: 20px;
}

.marginRight_25 {
  margin-right: 25px;
}

.marginRight_50 {
  margin-right: 50px;
}

/* margin left */
.marginLeft_5 {
  margin-left: 5px;
}

.marginLeft_10 {
  margin-left: 10px;
}

.marginLeft_15 {
  margin-left: 15px;
}

.marginLeft_20 {
  margin-left: 20px;
}

.marginLeft_25 {
  margin-left: 25px;
}

.marginLeft_30 {
  margin-left: 30px;
}

.marginLeft_50 {
  margin-left: 50px;
}

/* margin bottom */
.marginBottom_5 {
  margin-bottom: 5px;
}

.marginBottom_10 {
  margin-bottom: 10px;
}

.marginBottom_15 {
  margin-bottom: 15px;
}

.marginBottom_20 {
  margin-bottom: 20px;
}

.marginBottom_25 {
  margin-bottom: 25px;
}

.marginBottom_30 {
  margin-bottom: 30px;
}

/* padding */
.padding_0 {
  padding: 0;
}

.padding_2 {
  padding: 2px;
}

.padding_5 {
  padding: 5px;
}

.padding_10 {
  padding: 10px;
}

.padding_15 {
  padding: 15px;
}

.padding_20 {
  padding: 20px;
}

.padding_25 {
  padding: 25px;
}

.padding_30 {
  padding: 30px;
}

/* padding y */
.padding_y_2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.padding_y_5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding_y_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding_y_15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding_y_20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding_y_25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding_y_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* padding x */
.padding_x_2 {
  padding-left: 2px;
  padding-right: 2px;
}

.padding_x_5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding_x_10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding_x_15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding_x_20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding_x_25 {
  padding-left: 25px;
  padding-right: 25px;
}

.padding_x_30 {
  padding-left: 30px;
  padding-right: 30px;
}

/* padding top */
.paddingTop_5 {
  padding-top: 5px;
}

.paddingTop_10 {
  padding-top: 10px;
}

.paddingTop_15 {
  padding-top: 15px;
}

.paddingTop_20 {
  padding-top: 20px;
}

.paddingTop_25 {
  padding-top: 25px;
}

/* padding right */
.paddingRight_5 {
  padding-right: 5px;
}

.paddingRight_10 {
  padding-right: 10px;
}

.paddingRight_15 {
  padding-right: 15px;
}

.paddingRight_20 {
  padding-right: 20px;
}

.paddingRight_25 {
  padding-right: 25px;
}

.paddingRight_30 {
  padding-right: 30px;
}

.paddingRight_35 {
  padding-right: 35px;
}

.paddingRight_40 {
  padding-right: 40px;
}

.paddingRight_45 {
  padding-right: 45px;
}

/* padding left */
.paddingLeft_5 {
  padding-left: 5px;
}

.paddingLeft_10 {
  padding-left: 10px;
}

.paddingLeft_15 {
  padding-left: 15px;
}

.paddingLeft_20 {
  padding-left: 20px;
}

.paddingLeft_25 {
  padding-left: 25px;
}

.paddingLeft_30 {
  padding-left: 30px;
}

/* padding bottom */
.paddingBottom_5 {
  padding-bottom: 5px;
}

.paddingBottom_10 {
  padding-bottom: 10px;
}

.paddingBottom_15 {
  padding-bottom: 15px;
}

.paddingBottom_20 {
  padding-bottom: 20px;
}

.paddingBottom_25 {
  padding-bottom: 25px;
}

.paddingBottom_30 {
  padding-bottom: 30px;
}

/* cursor */
.pointer {
  cursor: pointer;
}

.cursor_default {
  cursor: default;
}

.hover_pointer:hover {
  cursor: pointer;
}

/* overflow */
.scroll_auto {
  overflow: auto;
}

.scroll_x_auto {
  overflow-x: auto;
}

.scroll_y_auto {
  overflow-y: auto;
}

.scroll_y {
  overflow-y: scroll;
}

.scroll_hidden {
  overflow: hidden;
}

/* text align */
.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

.text_justify {
  text-align: justify;
}

/* vertical align */
.ver_text_center {
  vertical-align: middle;
}

/* border radius */
.rounded_5 {
  border-radius: 5px;
}

.rounded_10 {
  border-radius: 10px;
}

.rounded_15 {
  border-radius: 15px;
}

.rounded_20 {
  border-radius: 20px;
}

.rounded_25 {
  border-radius: 25px;
}

.rounded_50 {
  border-radius: 50px;
}

.rounded_half {
  border-radius: 50%;
}

.rounded_full {
  border-radius: 100%;
}

/* no bullet style */
.no_list_style {
  list-style-type: none;
}

/* a */
.url:link {
  color: #0ca5eb;
}

.url:visited {
  color: #c66e10;
}

/* object-fit */

.object_cover {
  object-fit: cover;
}

.object_contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.z_0 {
  z-index: -1;
}

.z_5 {
  z-index: 5;
}

.hidden {
  display: none;
}

.no_wrap {
  white-space: nowrap;
}

/* grid */
.grid_2 {
  grid-template-columns: 50% 50%;
}

.grid_3 {
  grid-template-columns: 33.333% 33.333% 33.333%;
}

.grid_4 {
  grid-template-columns: 25% 25% 25% 25%;
}

.grid_5 {
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.grid_6 {
  grid-template-columns: 16.666% 16.666% 16.666% 16.666% 16.666% 16.666%;
}

.grid_7 {
  grid-template-columns: 14.285% 14.285% 14.285% 14.285% 14.285% 14.285% 14.285%;
}

.grid_8 {
  grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
}

.grid_9 {
  grid-template-columns: 11.111% 11.111% 11.111% 11.111% 11.111% 11.111% 11.111% 11.111% 11.111%;
}

.grid_10 {
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}

.grid_15 {
  grid-template-columns: 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666% 6.666%;
}

.span_1 {
  grid-column: span 1;
}

.span_2 {
  grid-column: span 2;
}

.span_3 {
  grid-column: span 3;
}

.span_4 {
  grid-column: span 4;
}

.span_5 {
  grid-column: span 5;
}

.span_6 {
  grid-column: span 6;
}

.span_7 {
  grid-column: span 7;
}

.span_8 {
  grid-column: span 8;
}

.span_9 {
  grid-column: span 9;
}

.span_10 {
  grid-column: span 10;
}

.invisible {
  visibility: hidden;
  /* position: absolute; */
  /* left: -9999px; */
}

.noborder {
  border: none;
}

.shadow {
  box-shadow: 2px 2px 5px #a6a6a6, -2px -2px 5px #ffffff;
}

.monospace {
  font-family: "Source Code Pro", monospace;
  /* font-family: "Courier New"; */
  -webkit-touch-callout: all;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.reset_all {
  all: unset;
}
