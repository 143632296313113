.container_project {
  position: relative;
  display: inline-block;
  width: 233px;
  max-width: 60vw;
  height: 233px;
  max-height: 60vw;
  border-radius: 10px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.container_folder {
  display: inline-block;
  width: 233px;
  max-width: 60vw;
  border-radius: 10px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.container_select_input {
  border-radius: 10px;
  border: 2px solid #d8d8d8;
  padding: 15px;
  margin-bottom: 20px;
}

.container_select_content {
  margin-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  height: 30px;
}

.container_select_input {
  border-radius: 10px;
  border: 2px solid #d8d8d8;
  padding: 15px;
  margin-bottom: 20px;
}

.container_notification {
  background-color: #f3f6fd;
  padding: 17px 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.container_notification div {
  display: inline-block;
}

.container_notification b {
  cursor: pointer;
}

.container_notification section {
  margin-top: 10px;
}

.container_layer_card {
  min-width: 160px;
  width: 160px;
  height: 100px;
  margin-right: 10px;
  background-color: #0ca5eb;
  color: #fff;
  position: relative;
  padding: 10px;
  border-radius: 15px;
}

.container_layer_card_name {
  text-align: left;
  height: 50px;
  overflow: hidden;
  font-weight: bold;
}

.container_request {
  background-color: #0ca5eb;
  border-radius: 2rem;
  padding: 3px 20px 3px 3px;
  color: #fff;
  font-weight: bold;
}

.container_badge {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: #fff;
  color: #0ca5eb;
  border-radius: 2rem;
  font-size: 11px;
  font-weight: 600;
  padding: 3px 7px 3px 7px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
}

.container_blog_status {
  display: inline-block;
  border-radius: 20px;
  width: 210px;
  max-width: 80vw;
  height: 250px;
  padding: 15px;
  margin: 10px;
  text-align: left;
  vertical-align: top;
  position: relative;
}

.container_blog_status_img {
  display: inline-block;
  vertical-align: top;
  height: 27px;
  width: 27px;
  padding: 10px;
  background-color: #ecf6fe;
  border-radius: 2rem;
  margin-right: 15px;
  margin-bottom: 30px;
}

.container_blog_status_img_like {
  height: 27px;
  width: 27px;
  padding: 10px;
  background-color: #ecf6fe;
  border-radius: 2rem;
  margin-right: 15px;
  margin-bottom: 30px;
}

.container_blog_paragraph {
  display: inline-block;
  vertical-align: top;
}

.container_blog_footer {
  position: absolute;
  bottom: 15px;
  width: calc(100% - 30px);
}

.container_standard {
  border-radius: 10px;
  padding: 10px;
}

.container_blog_card {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 230px;
  max-width: 60vw;
  margin: auto;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  text-align: left;
  border: 2px solid #9eb3c0;
}

.container_square {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}

.container_image_blog {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.container_second_half_blog {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  border-radius: 10px;
}

.container_first_half_blog {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.container_card_footer {
  position: absolute;
  bottom: 0px;
}

.container_full {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.container_grey {
  background-color: rgb(223, 231, 235);
  color: #000000;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 400;
  padding: 10px;
}

.container_light {
  border-radius: 10px;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
}

.container_flat {
  background-color: rgb(230, 238, 243);
  border-radius: 7px;
  font-size: 12px;
  padding: 10px;
}

.container_small {
  width: 130px;
  min-height: 70px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border-radius: 5px;
}

.container_icon {
  background-color: rgb(230, 238, 243);
  border-radius: 7px;
  font-size: 12px;
  padding: 5px;
}

.container_basic {
  text-align: center;
  text-decoration: none;
  border-radius: 15px;
  width: 150px;
  min-height: 120px;
}

.inside_center_big {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  height: 50px;
  width: 50px;
  text-align: center;
}

.four-container {
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  grid-gap: 10px;
}

.two_container_100px_auto {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 10px;
}

.four_container_10_40_10_40 {
  display: grid;
  grid-template-columns: 10% 40% 10% 40%;
  grid-gap: 5px;
}

.four_container_100px_auto_100px_auto {
  display: grid;
  grid-template-columns: 100px auto 100px auto;
  grid-gap: 10px;
}

.five-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 10px;
}

.five-container-no-gap {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.three-container {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 10px;
}

.three_container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}

.three_container_45_10_45 {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  grid-gap: 10px;
}

.two-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.two_container_auto {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.two_container_small_right {
  display: grid;
  grid-template-columns: auto 20px;
  grid-gap: 10px;
}

.two_container {
  display: grid;
  grid-template-columns: auto 100%;
  grid-gap: 20px;
}

.two_container_equal {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-gap: 10px;
}

.two-container-input {
  display: grid;
  grid-template-columns: 200px auto;
  grid-gap: 10px;
}

.two_container_list {
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 10px;
}

.two_container_button_right_small {
  display: grid;
  grid-template-columns: calc(100% - 25px) 33px;
  grid-gap: 0px;
}

.input_icon {
  display: grid;
  grid-template-columns: auto 30px;
  grid-gap: 5px;
  align-items: center;
  padding: 0 10px;
}

.carousell {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.carousell #layer_component {
  margin-left: 7px;
  margin-right: 7px;
}

.container {
  border-radius: 7px;
  padding: 10px;
}

.box {
  background-color: #deebf2;
  padding: 10px;
  border-radius: 10px;
}

.half {
  display: inline-block;
  vertical-align: top;
  width: 450px;
  max-width: 70vw;
  margin-bottom: 10px;
  margin-right: 10px;
}

.side {
  display: inline-block;
  vertical-align: top;
}

.inline_block {
  display: inline-block;
  vertical-align: top;
  width: 400px;
  max-width: 100%;
  margin: auto;
}

.upload_pp_container {
  margin-top: 5px;
  cursor: pointer;
  width: 83px;
  height: 20px;
  border: 2px dashed #c9c9c9;
  border-radius: 7px;
}

.parent_section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 170px);
  overflow: hidden;
}

.parent_section_full {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 10px;
  overflow: hidden;
  box-sizing: border-box;
}

.section_2 {
  flex-grow: 1;
  overflow-y: auto;
}

.sidebar_home {
  width: 200px;
  height: calc(100svh - 140px);
  padding-bottom: 70px;
  padding-right: 10px;
  padding-top: 10px;
  background: #fff;
  position: fixed;
  top: 60px;
  left: 0px;
  z-index: 3;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.sidebar_home.open {
  transform: translateX(0);
}

.main_container {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: -70px;
  padding-top: 70px;
}

.carousel_wrapper {
  margin: auto;
  width: 1000px;
  max-width: 100%;
}

.carousel_container {
  width: 1000px;
  max-width: 100%;
  padding-top: 25%;
  /* 1:4 Aspect Ratio */
  display: flex;
  position: relative;
}

.carousel_image {
  width: 1000px;
  max-width: 100%;
  border-radius: 0.8em;
  max-height: 300px;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  transform: scale(0.95);
  transition: all 0.3s ease-out;
}

.carousel_image.active {
  opacity: 1;
  transform: scale(1);
}

.carousel_wrapper_case {
  margin: auto;
}

.carousel_container_case {
  padding-top: 25%;
  /* 1:4 Aspect Ratio */
  display: flex;
  position: relative;
}

.carousel_case {
  width: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  transform: scale(0.95);
  transition: all 0.3s ease-out;
}

.carousel_case.active {
  opacity: 1;
  transform: scale(1);
}

.carousel_button_prev,
.carousel_button_next {
  position: absolute;
  top: 50%;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff47;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 6px #9d9d9d53;
}

.carousel_button_prev {
  left: 10px;
}

.carousel_button_next {
  right: 10px;
}

.carousel_button_prev::after,
.carousel_button_next::after {
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 54%;
  border-right: 2px solid rgba(71, 71, 71, 0.615);
  border-bottom: 2px solid rgba(71, 71, 71, 0.615);
  transform: translate(-50%, -50%) rotate(135deg);
}

.carousel_button_next::after {
  left: 47%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.carousel_button_next:hover {
  background-color: #ffffff;
}

.carousel_button_prev:hover {
  background-color: #ffffff;
}

.container_document {
  width: 600px;
  max-width: 80vw;
  margin-right: auto;
  margin-left: auto;
}

.margin_1 {
  margin-right: 3px;
  margin-bottom: 3px;
}

.margin_top {
  margin-top: 7px;
}

.margin_bottom {
  margin-bottom: 7px;
}

.margin_right {
  margin-right: 7px;
}

.margin_left {
  margin-left: 7px;
}

.margin_top_minor {
  margin-top: 1px;
}

.margin_bottom_minor {
  margin-bottom: 1px;
}

.margin_right_minor {
  margin-right: 1px;
}

.margin_left_minor {
  margin-left: 1px;
}

.margin_left_double {
  margin-left: 14px;
}

.margin_bottom_extra {
  margin-bottom: 15px;
}
