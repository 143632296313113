.footer_margin {
  margin-bottom: 150px;
}

.brand_map {
  z-index: 1;
  position: fixed;
  top: 10px;
  left: 23px;
}

.auth_right {
  position: absolute;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  gap: 1rem;
  transition: 1s all ease-in-out;
  margin-top: 120px;
}

.auth_content_left {
  height: 60px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.auth_content_left label:nth-child(1) {
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
}

.auth_content_left p {
  font-size: 0.8rem;
  color: #fff;
  font-weight: 700;
}

.auth_content_left label:nth-child(2) {
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
}

.info_icon {
  width: 20px;
  height: 20px;
}

.nav_login_left img {
  height: 10px;
  padding-left: 0px;
}

.auth_content {
  display: flex;
  height: 100%;
  background-color: #fff;
}

.auth_content_right {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.nav_login_right {
  margin-right: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.auth_language {
  margin-right: 20px;
}

.step_progress {
  background-color: white;
  color: black;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid silver;
  cursor: pointer;
}

.step_progress.active {
  background-color: black;
  color: white;
  border: 2px solid black;
}

.nav_login {
  z-index: 0;
  display: flex;
  align-items: end;
  justify-content: space-between;
  height: 80px;
  position: fixed;
  width: 100svw;
}

.auth_left {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100svh;
}

.box_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.box_content h1 {
  font-weight: 700;
  font-size: 1.875rem;
  color: #343434;
}

.box_content p:first-child {
  font-size: 0.875rem;
}

.box_content_bottom {
  width: 100%;
  text-align: center;
}

.connected_future_auth {
  margin-top: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 0;
}

.container_column {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr; /* Kolom menjadi satu */
}

.container_column_special {
  display: grid;
  gap: 10px; /* Jarak antar elemen */
  grid-template-columns: 1fr; /* Kolom menjadi satu */
}

.card_uji_legal_hbu {
  grid-column: span 1;
}

.floating_button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

@media screen {
	.hide_component {
		visibility: hidden;
	}
}

@media (min-width: 200px) {
  .nav_login_left img {
    height: 10px;
    padding-left: 5px;
  }
}

@media (min-width: 320px) {
  body {
    padding-top: 80px;
  }

  .card_qouta_price {
    width: 280px;
    height: 237px;
  }

  .nav_login_left img {
    height: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 380px) {
  .connected_future_auth {
    margin-right: 30px;
  }

  .auth_language {
    margin-right: 30px;
  }
}

@media (min-width: 400px) {
  .nav_login_left {
    height: 25px;
  }

  .nav_login_left img {
    height: 25px;
    padding-left: 40px;
  }

  .connected_future_auth {
    margin-right: 35px;
  }

  .auth_language {
    margin-right: 35px;
  }
}

@media (min-width: 480px) {
  body {
    padding-top: 80px;
  }

  .connected_future_auth {
    margin-right: 55px;
  }

  .auth_language {
    margin-right: 55px;
  }
}

@media (max-width: 480px) {
  .btn-map-style {
    flex-wrap: wrap;
    width: 200px;
  }
}

@media (min-width: 600px) {
  body {
    padding-top: 80px;
  }

  .card_qouta_price {
    width: 277px;
    height: 300px;
  }

  .search_bar {
    width: 500px;
  }

  .brand_map {
    top: 15px;
  }

  .box_content {
    width: 460px;
  }
}

@media (min-width: 801px) {
  body {
    padding-top: 80px;
  }

  .auth_right {
    position: relative;
    margin-top: 95px;
    background: rgba(0, 0, 0, 0);
  }

  .auth_content_left {
    height: 10rem;
    margin-top: 120px;
    display: flex;
    justify-content: start;
  }

  .auth_content_left label:nth-child(1) {
    display: block;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    transition: 0.5s all ease-in-out;
  }

  .auth_content_left p {
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
  }

  .auth_content_left label:nth-child(2) {
    display: block;
    text-transform: capitalize;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    line-height: 29px;
  }

  .info_icon {
    width: 30px;
    height: 30px;
  }

  .nav_login_left img {
    height: 45px;
    padding-left: 20px;
  }

  .nav_login_right {
    width: calc((852 - 315) / (1448 - 800) * (100vw - 800px) + 315px);
    margin-right: 35px;
  }

  .auth_language {
    margin-top: 0px;
    margin-right: 35px;
  }

  .box_content {
    width: 300px;
  }
}

@media (min-width: 1025px) {
  body {
    padding-top: 80px;
  }

  .footer_margin {
    margin-bottom: 50px;
  }

  .card_qouta_price {
    width: 277px;
    height: 300px;
  }

  .auth_left {
    width: 800px;
  }

  .box_content {
    width: 550px;
  }

  .box_content h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #343434;
  }

  .box_content p {
    font-size: 1.25rem;
  }
}

@media (min-width: 1281px) {
  body {
    padding-top: 80px;
  }
}

@media (min-width: 900px) {
  body {
    padding-top: 80px;
  }

  #main_container {
    margin-left: 210px;
  }

  .main_container {
    margin-left: 210px;
  }

  .container_column {
    grid-template-columns: repeat(3, 1fr); /* Tiga kolom dengan lebar sama */
  }

  .card_uji_legal_hbu {
    grid-column: span 2;
  }
}

@media (min-width: 1500px) {
  body {
    padding-top: 80px;
  }
}

@media (min-width: 1920px) {
  body {
    padding-top: 80px;
  }

  .auth_left {
    width: 1267px;
  }
}

.row_blog_title {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 5rem 0 12rem;
  align-items: center;
  justify-content: flex-start;
  transition: all ease-in-out 0.5s;
}

.title_blog_nav {
  font-size: 1.7rem;
  font-weight: 500;
  width: 90%;
  height: 60%;
  padding-left: 10px;
  transition: all ease-in-out 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: default;
}

.title_blog_nav_hide {
  font-size: 0rem;
  font-weight: 100;
  width: 100%;
  height: 0%;
  transition: all ease-in-out 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

#blog_active {
  background-color: #0ca5eb;
  color: white;
}

#blog_active p {
  color: #fff;
}

#blog_active h1 {
  color: #fff;
}

#blog_not_active {
  background-color: #fff;
}

.flex_container {
  display: flex;
}

.flex_child {
  flex: 1;
  margin: auto;
  white-space: nowrap;
  font-size: 14px;
}

.flex_child:first-child {
  margin-right: 10px;
}

.text_below_icon {
  display: inline-block;
  width: 30%;
  height: 40px;
  text-align: center;
  transition: transform 0.1s ease-out;
  color: #828282ff;
  font-size: 10px;
}

.text_below_icon:hover {
  transform: scale(1.1);
}

.parent_tile {
  column-width: 220px;
  column-gap: 10px;
}

.child_tile {
  padding: 10px;
  /* margin-bottom: 10px; */
  display: inline-block;
  /* width: 100%; */
}

.column_size_small {
  width: 165px;
  max-width: 50vw;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  overflow-wrap: break-word;
  word-break: break-word;
  box-sizing: border-box;
  white-space: normal;
}

.column_size_medium {
  width: 250px;
  max-width: 50vw;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  overflow-wrap: break-word;
  word-break: break-word;
  box-sizing: border-box;
  white-space: normal;
}

#bg_white {
  background-color: #ffffff;
}

.phone_number {
  display: grid;
  grid-template-columns: 100px auto;
}

.login_register {
  margin-top: 200px;
  margin-bottom: 300px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 500px;
}

.landing-grid {
  display: grid;
  grid-template-columns: 50vw 50vw;
}

.landing-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding-top: 0px;
  padding-left: 50px;
  padding-right: 50px;
}

.form-rounded {
  border-radius: 1rem;
}

.icon-text {
  display: grid;
  grid-template-columns: 16px auto 20px;
  grid-gap: 0px;
  align-items: center;
}

.input_rounded {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.04);
  padding: 3px 20px 3px 20px;
  text-align: left;
  text-decoration: none;
  color: #5e5e5e;
}

.vertical_center {
  display: inline-grid;
}

.vertical_center_child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.vertical_horizontal_center_child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_instant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.horizontal_center {
  justify-content: center;
  align-items: center;
}

.upload_container {
  width: 100px;
  height: 100px;
  border: 2px dashed #c9c9c9;
  border-radius: 7px;
}

@media only screen and (min-width: 300px) and (max-width: 1000px) {
  .tools_bar {
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: #fff;
    z-index: 4;
    box-shadow: 0 0 6px #9d9d9d53;
    padding: 10px 20px 10px 0px;
  }

  .container_document_import {
    position: fixed;
    top: 200px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 100px;
    height: calc(100vh - 300px);
    width: 350px;
    background-color: #ffffff;
    border-right: 1px solid rgb(171, 183, 194);
    overflow-y: auto;
    z-index: 3;
  }

  .container_blog_editor {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .container_document_new {
    padding-right: 50px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .tools_bar {
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: #fff;
    z-index: 4;
    box-shadow: 0 0 6px #9d9d9d53;
    padding: 10px 20px 10px 0px;
  }

  .container_layer_and_project {
    position: sticky;
    top: 210px;
    height: auto;
    width: 550px;
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 50px;
  }

  .container_document_import {
    position: fixed;
    top: 0px;
    margin-top: 200px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 100px;
    height: calc(100vh - 300px);
    width: 350px;
    border-right: 1px solid rgb(171, 183, 194);
    overflow-y: auto;
  }

  .container_blog_editor {
    width: 100%;
  }

  .container_document_new {
    padding-right: 150px;
    padding-left: 100px;
  }
}

.card_layer_content {
  text-align: left;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.scale_animate {
  animation: scale_animate 0.2s alternate infinite linear;
}

@keyframes scale_animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-map-style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageStandard {
  display: block;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}

.buttonCenter {
  display: inline-block;
  width: 43px;
  height: 30px;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  vertical-align: bottom;
  position: relative;
}

.icon_list_render {
  position: absolute;
  height: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke: red;
  color: yellow;
  fill: green;
  color: blue;
}

.button_account {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #1e5e96ff;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 0.9rem;
  margin-right: 10px;
}

.button_register {
  border-radius: 0.6rem;
  background: transparent;
  border: none;
  color: #00de59;
  padding: 5px 0px 5px 0px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
}

.forget_password {
  margin: 10px 0 5px 0;
}

.forget_password p {
  text-align: right;
  color: #919191;
}

.openbtn {
  position: fixed;
  top: 60px;
  left: 305px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50% 50% 50% 50%;
  background-color: #2ca1fdff;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.openbtnAnalyzer {
  position: fixed;
  top: 75px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50% 50% 50% 50%;
  background-color: #2ca1fdff;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.sidebar {
  position: fixed;
  top: 0px;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  width: 330px;
  max-width: 80vw;
  margin-bottom: 200px;
  padding: 20px 18px 10px 18px;
  background: #ffffff;
  box-sizing: border-box;
}

.inputBox {
  background-color: #62cdf2ff;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 7px 15px 7px 15px;
  margin-bottom: 10px;
  height: 19px;
  text-align: left;
  color: #ffffff;
  overflow: hidden;
  font-size: 14px;
  font-weight: 450;
  letter-spacing: -0.2px;
}

.inputBoxHome {
  background-color: transparent;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 7px 0px 7px 0px;
  margin-bottom: 10px;
  text-align: left;
  color: #ffffff;
  overflow: hidden;
  transition: height 0.1s ease-out;
  -webkit-transition: height 0.1s ease-in-out;
  -moz-transition: height 0.1s ease-in-out;
  -o-transition: height 0.1s ease-in-out;
}

.folderBox {
  background-color: transparent;
  border: 2px solid #1e5e96ff;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 3px 5px 3px 5px;
  margin-bottom: 10px;
  text-align: left;
  color: #5e5e5e;
  overflow: hidden;
}

.layer_box {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 2px solid #b5b5b5;
  margin-bottom: 10px;
  text-align: left;
  padding: 10px;
}

#box_active {
  background-color: #9cd4f5ff;
  color: #3e3e3e;
}

#box_non_active {
  background-color: #fff;
  color: #3e3e3e;
}

#box_non_active_grey {
  background-color: rgb(223, 231, 235);
  color: #000000;
}

.layerBox {
  background-color: #fff;
  color: #3e3e3e;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 2px solid #b5b5b5;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.layerBoxSelected {
  background-color: #9cd4f5;
  color: #3f4041;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 2px solid #b5b5b5;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.layerBoxBottom {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  margin-right: 5px;
  margin-left: 5px;
  height: 90px;
  font-size: 13px;
  position: relative;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  text-align: left;
  padding: 5px 7px;
  border: 2px solid #b5b5b5;
}

.ilustration_sini_max_width {
  max-width: 100%;
  width: 200px;
}

.layer_box_section {
  cursor: pointer;
  height: 33px;
  overflow: hidden;
  margin-bottom: 20px;
}

.layerBoxBottom_transparent {
  width: 430px;
  max-width: 430px;
  min-width: 430px;
  height: 90px;
  position: relative;
}

.select_folder {
  left: 10;
  margin-left: 10px;
  margin-bottom: 5px;
  text-align: left;
  width: 200px;
}

@media only screen and (max-width: 650px) {
  .select_folder {
    margin-left: 5px;
    margin-bottom: 10px;
    text-align: left;
    width: 150px;
  }

  .layerBoxBottom {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    margin-right: 5px;
    margin-left: 5px;
    height: 70px;
    font-size: 13px;
    position: relative;
    border-radius: 0.5rem;
    text-align: left;
    padding: 7px;
  }

  .layer_box_section {
    cursor: pointer;
    height: 34px;
    overflow: hidden;
    margin-bottom: 0;
  }

  .layerBoxBottom_transparent {
    width: 0px;
    max-width: 0px;
    min-width: 0px;
    height: 0px;
    position: absolute;
  }
}

.layerBoxFooter {
  display: inline-block;
  position: absolute;
  bottom: 10px;
}

.active {
  background-color: #1e5e96;
  color: #ffffff;
}

.non-active {
  background-color: white;
  color: #5e5e5e;
}

#active_strong {
  color: #fff;
  background: #1799dd;
  border: 3px solid #1799dd;
}

#active {
  background-color: #c0e4ff;
  color: #000000;
}

#non_active {
  background-color: white;
  color: #000000;
}

#on {
  background-color: #0ca5eb;
  color: #fff;
}

#off {
  background-color: #c0e4ff;
  color: #fff;
}

#not_active:hover {
  background-color: #f4f4f4;
}

#button_active {
  background-color: #0ca5eb;
  color: #ffffff;
}

#button_non_active {
  background-color: rgb(223, 231, 235);
  color: #000000;
}

.layer_name {
  margin-bottom: 5px;
  max-height: 49px;
  cursor: text;
  overflow: hidden;
  font-size: 15px;
  font-weight: 470;
  line-height: 110%;
  letter-spacing: -0.3px;
}

.folder_name {
  color: #939393;
  margin-bottom: 5px;
  max-height: 30px;
  cursor: text;
  overflow: hidden;
  font-size: 13px;
  font-weight: 470;
  line-height: 110%;
  letter-spacing: -0.3px;
}

#edit_path {
  cursor: pointer;
  fill: #dededede;
}

#delete_path {
  cursor: pointer;
  fill: #dededede;
}

#delete_path_fill {
  cursor: pointer;
  color: white;
}

.layer_box:hover #edit_path {
  fill: #2064a1ff;
}

.layer_box:hover #delete_path {
  fill: #ca0b00;
}

.layer_name:hover #edit_path {
  fill: #2064a1ff;
}

.layer_name:hover #delete_path {
  fill: #ca0b00;
}

#icon_delete:hover {
  transform: scale(1.1);
}

#icon_edit:hover {
  transform: scale(1.1);
}

#icon_add:hover {
  transform: scale(1.1);
}

.header_table #edit_path {
  fill: #ffffff;
}

#icon_edit {
  margin: 0px;
  margin-right: 4px;
}

.top_nav {
  width: 100%;
  max-width: 100vw;
  background: #ffffff;
  border-bottom: 1.5px solid rgb(232, 232, 232);
  position: fixed;
  top: 0;
  z-index: 3;
  margin: 0;
}

.top_nav_container {
  position: relative;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.top_nav_document {
  width: 100%;
  max-width: 100vw;
  background: #ffffffe3;
  border-bottom: 1.5px solid rgb(232, 232, 232);
  position: fixed;
  top: 0;
  z-index: 3;
}

.top_nav_container_document {
  height: 60px;
  position: relative;
}

.left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 30px;
  cursor: pointer;
}

.right {
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 30px;
}

.search_bar {
  height: 45px;
  border-radius: 7px;
  background-color: #fff;
  border: 2px solid #d8d8d8;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.kuota_akses {
  height: 45px;
  border-radius: 25px;
  cursor: pointer;
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.kuota_akses h1 {
  font-size: 1.2rem;
  font-weight: 600;
}

.kuota_akses:hover {
  background-color: #e5ecf0;
}

.result_item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0;
  border-bottom: 3px solid #efefef;
}

.result_item:hover {
  background-color: #d8d8d8;
}

.result_name {
  font-size: 13px;
  margin-bottom: 5px;
  cursor: pointer;
}

.sidebar_button {
  color: black;
  height: 50px;
  width: 100%;
  border-radius: 0% 2rem 2rem 0%;
  font-weight: 450;
  cursor: pointer;
}

.buttonBoxy {
  border: 2px solid #d8d8d8;
  background: #efefef;
  color: black;
  padding: 3px 10px 3px 10px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  word-wrap: break-word;
}

.table-toggle-height {
  position: absolute;
  width: 100px;
  bottom: 300px;
  left: 340px;
  background-color: #0ca5eb;
  color: white;
  text-align: center;
  z-index: 2;
}

.table_generate_form_edit {
  position: absolute;
  width: 100px;
  bottom: 300px;
  left: 480px;
  background-color: #0ca5eb;
  color: white;
  text-align: center;
  z-index: 2;
}

.table_tutorial {
  position: absolute;
  width: 100px;
  bottom: 300px;
  left: 620px;
  background-color: #0ca5eb;
  color: white;
  text-align: center;
  z-index: 2;
}

table.data-grid {
  margin: auto;
  width: 100%;
  position: relative;
  border-collapse: collapse;
}

.table-button-header {
  position: fixed;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: white;
}

.table-render-container {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 100px;
}

.data-grid-container .data-grid {
  border-collapse: collapse;
  width: auto;
  min-width: 100%;
  margin: auto;
  padding: 10;
  position: relative;
  height: auto;
  overflow-y: auto;
}

.data-grid-container .data-grid .cell.read-only {
  cursor: pointer;
}

table.data-grid tbody tr td {
  width: auto;
  min-width: max-content;
}

table.data-grid thead {
  position: sticky;
}

table.data-grid thead tr th {
  position: sticky;
  top: 0;
}

table.data-grid thead tr th.buttonBoxy {
  width: 1%;
  white-space: nowrap;
  min-width: max-content;
}

table.data-grid tbody tr td.action-cell {
  max-width: 20px;
  width: 1%;
}

table.data-grid tbody tr td.cell {
  width: 1%;
  white-space: nowrap;
  min-width: max-content;
}

table.data-grid tbody th {
  position: sticky;
  top: 0;
}

table.data-grid-container .data-grid {
  border-collapse: separate;
  border-spacing: 0;
}

.tableBar {
  overflow-y: scroll;
  overflow-x: scroll;
  position: fixed;
  bottom: 0px;
  right: 0;
  height: 300px;
  z-index: 1;
  width: calc(100vw - 335px);
  background-color: #ffffff;
  color: #1e5e96;
}

.tableBar.small {
  height: 300px;
}

.tableBar.half {
  height: 50%;
}

.tableBar.full {
  height: 100%;
}

.tableBarBottom {
  overflow-y: scroll;
  overflow-x: scroll;
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 1;
  width: 100vw;
  height: 320px;
  background-color: #ffffff;
  color: #1e5e96ff;
  box-shadow: 0 0 30px #67676764;
  -webkit-box-shadow: 0 0 30px #67676764;
  -moz-box-shadow: 0 0 30px #67676764;
}

.bottomBar {
  position: fixed;
  bottom: 20px;
  left: 0px;
  z-index: 1;
  max-width: 100vw;
}

.button_footer {
  position: absolute;
  bottom: 10px;
  margin-left: 20px;
}

@media screen and (min-width: 300px) {
  .header_medium {
    font-size: 25px;
    font-weight: 505;
    letter-spacing: -1.3px;
    line-height: 25px;
  }
}

@media screen and (min-width: 1000px) {
  .header_medium {
    font-size: 35px;
    font-weight: 505;
    letter-spacing: -1.6px;
    line-height: 25px;
  }
}

.header {
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.sheet-container {
  display: block;
  padding: 5px;
  -webkit-box-shadow: 0 0 6px #ccc;
  box-shadow: 0 0 6px #ccc;
  margin: auto;
  width: 500px;
  margin-top: 20px;
}

.tableStyle {
  font-size: 10px;
  background-color: white;
  color: black;
  text-align: left;
}

.table_lite {
  font-size: 12px;
  text-align: left;
  color: black;
  padding: 5px;
  border-collapse: collapse;
  height: auto;
  overflow-y: auto;
  min-width: 100%;
  overflow-x: auto;
  background-color: #fff;
  border: 2px solid #000000;
  margin: 5px 5px 100px 5px;
}

.table_lite tbody tr td {
  border: 2px solid #000000;
}

.table_lite thead {
  background-color: #dedede;

  border: 2px solid #000000;
}

.table_lite th {
  border: 2px solid #000000;
  font-weight: bold;
  text-align: left;
  padding: 5px;
  background-color: #3aabe6;
  color: #fff;
  vertical-align: top;
  position: sticky;
  top: 0;
}

.table_lite td {
  text-align: left;
  padding: 5px;
  vertical-align: top;
  border: 2px solid #000000;
}

.table_lite tr:nth-child(even) {
  background-color: #f0f0f0;
  vertical-align: top;
}

.table_pch {
  font-size: 12px;
  text-align: left;
  color: black;
  padding: 5px;
  border-collapse: collapse;
  background-color: #fff;
  border: 2px solid #000000;
}

.table_pch tbody tr td {
  border: 2px solid #000000;
}

.table_pch thead {
  background-color: #dedede;
  border: 2px solid #000000;
}

.table_pch th {
  border: 2px solid #000000;
  font-weight: bold;
  text-align: left;
  padding: 5px;
  color: #000000;
  vertical-align: top;
}

.table_pch td {
  text-align: left;
  padding: 5px;
  vertical-align: top;
  border: 2px solid #000000;
}

.table_pch tr:nth-child(even) {
  background-color: #f0f0f0;
  vertical-align: top;
}

.table_white {
  font-size: 12px;
  position: relative;
  border-collapse: collapse;
  background-color: #ffffff;
  border-color: transparent;
}

.table_white td,
th {
  background-color: #ffffff;
  border-color: transparent;
}

.table {
  font-size: 12px;
  position: relative;
  border-collapse: collapse;
  background-color: #ffffff;
}

.table td,
th {
  padding: 0.25em;
  vertical-align: top;
}

.table thead th tr {
  border: 2px solid #000000;
  background: #ffffff;
}

.table td {
  border: 2px solid #729fa1;
}

.table thead th {
  position: sticky;
  top: 0;
  background: #ffffff;
  color: #000000;
  border: 2px solid #000000;
}

.table thead th:first-child {
  left: 0;
  z-index: 1;
}

.table tbody th {
  position: sticky;
  left: 0;
  background: #ffffff;
  border-right: 1px solid #000000;
}

.absolute_table {
  z-index: 999;
  position: relative;
}

.toggle_statistik {
  position: fixed;
  top: 147px;
  right: 10px;
  height: 30px;
  width: 30px;
  font-size: 20px;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.094);
  background-color: white;
  z-index: 32;
  cursor: pointer;
}

.toggle_sini {
  position: fixed;
  top: 185px;
  right: 10px;
  height: 30px;
  width: 30px;
  padding: 2px;
  text-align: center;
  font-size: 20px;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.094);
  background-color: white;
  z-index: 32;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.toggle_map_mode {
  position: fixed;
  top: 220px;
  right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.094);
  background-color: white;
  z-index: 32;
  cursor: pointer;
}

.toggle_toolbox_mode {
  position: absolute;
  top: 150px;
  right: 10px;
  height: 31px;
  width: 31px;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.094);
  background-color: white;
  z-index: 32;
  cursor: pointer;
}

.modal_left_side {
  position: absolute;
  right: 40px;
  z-index: 32;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.094);
  background-color: white;
}

.img-rounded {
  border-radius: 5px;
}

.text_toggle_statistik {
  font-weight: 800;
  font-size: 17px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dummy_search {
  top: 15px;
  left: 80px;
  position: fixed;
  width: 400px;
  max-width: 70vw;
  z-index: 2;
  background-color: #fff;
  text-align: left;
  margin-bottom: 5px;
  padding: 10px 10px;
  display: inline-block;
  border: 2px solid #aeb0b1ff;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #515151;
  font-family: "Figtree", sans-serif;
  height: 40px;
}

.search_bar_lite {
  top: 15px;
  left: 80px;
  position: fixed;
  width: 400px;
  max-width: 70vw;
  z-index: 2;
  border: 2px solid #aeb0b1ff;
}

.search_bar_x {
  background-color: #fff;
  padding: 10px 10px;
  font-size: 16px;
  color: #515151;
  text-align: center;
  border-radius: 10px;
  box-sizing: border-box;
  height: 40px;
  border: 2px solid #aeb0b1ff;
  cursor: pointer;
}

.search_bar_layer {
  top: 60px;
  left: 80px;
  position: fixed;
  width: 400px;
  max-width: 70vw;
  z-index: 2;
  background-color: #fff;
  text-align: left;
  padding: 10px 10px;
  display: inline-block;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #7d7d7d;
  font-family: "Figtree", sans-serif;
  height: 40px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
}

.search_bar_lite .search-lite-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  top: 50px;
  z-index: 1;
}

.search_bar_lite:hover .search-lite-tooltip {
  visibility: visible;
}

.search_bar_lite input {
  overflow: hidden;
  left: 5px;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  color: rgb(87, 87, 87);
  text-align: left;
}

.box_mobile {
  width: 100vw;
  height: 90vh;
  padding-bottom: 3rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  bottom: -65%;
  right: 0;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  -moz-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  overflow: hidden;
}

.button_show_mobile {
  width: 100px;
  background-color: #0ca5eb;
  height: 10px;
  border-radius: 10px;
  z-index: 999;
}

.button_mobile {
  width: 100%;
  height: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_scroll {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.table_statistic {
  font-size: 12px;
  text-align: left;
  color: black;
  padding: 5px;
  border-collapse: collapse;
}

.table_statistic th {
  font-weight: bold;
  border: 2px solid #e3e3e3;
  text-align: left;
  padding: 5px;
  background-color: #0575e6;
  color: white;
  vertical-align: top;
}

.table_statistic td {
  border: 2px solid #e3e3e3;
  text-align: left;
  padding: 5px;
  vertical-align: top;
}

#table-popup-props {
  word-wrap: break-word;
  border: 2px solid DarkGray;
  overflow-x: auto;
  border-collapse: collapse;
}

#table-popup-image {
  word-wrap: break-word;
  border: 0px solid;
  overflow-x: auto;
  border-collapse: collapse;
}

#table-popup-props th {
  word-wrap: break-word;
  border: 2px solid DarkGray;
  border-collapse: collapse;
}

#table-popup-props tr {
  word-wrap: break-word;
  border: 2px solid DarkGray;
  border-collapse: collapse;
}

#table-popup-props td {
  word-wrap: break-word;
  border: 2px solid DarkGray;
  border-collapse: collapse;
}

.arrow_up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgb(225, 225, 225);
}

.arrow_down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #f00;
}

.arrow_right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid green;
}

.arrow_left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid blue;
}

.table_paid_form {
  width: 100%;
}

.table_paid_form td {
  padding: 20px;
  color: #52606e;
}

.meragmerh {
  background-color: #00f260;
}

.tabel_top {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.full_screen {
  padding: 5px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
}

.use_margin {
  margin: 3px;
}

.no_margin {
  margin: 0;
}

.detail_profil_value {
  display: flex;
  background-color: #ffffff;
  border-radius: 7px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 3rem;
}

.detail_profile_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.detail_profile_paragraf {
  display: flex;
  flex-direction: column;
  margin: 5px 7px;
  width: 220px;
  font-size: 15px;
  color: #000000;
}

.detail_profile_paragraf p {
  color: #1e5e96;
  font-weight: 300;
  font-size: 0.8rem;
}

.detail_profile_paragraf span {
  margin-top: -5px;
  font-weight: 500;
  font-size: 1rem;
}

.save_button {
  border: 2px solid #1e5e96;
  color: #1e5e96;
  width: 100%;
  font-size: 1rem;
  padding: 7px;
  border-radius: 7px;
  font-weight: 500;
  transition: 0.3s all ease-in;
}

.save_button:hover {
  color: #ffffff;
  background-color: #1e5e96;
  transition: 0.3s all ease-out;
}

.edit_button_profile {
  border: 2px solid #0ca5eb;
  color: #ffffff;
  background-color: #0ca5eb;
  font-size: 0.7rem;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  font-weight: 600;
  border-radius: 4px;
  transition: 0.2s all ease-in-out;
  margin: 0 5px;
}

.edit_button_profile:hover {
  color: #0ca5eb;
  background-color: transparent;
  transition: 0.2s all ease-out;
}

.logout_button_profile {
  cursor: pointer;
  border: 2px solid #ff0000;
  color: #ff0000;
  font-size: 0.7rem;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  font-weight: 600;
  border-radius: 4px;
  transition: 0.2s all ease-in-out;
  margin: 0 5px;
}

.logout_button_profile:hover {
  color: #ffffff;
  background-color: #ff0000;
  transition: 0.2s all ease-out;
}

.down_show {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.down_show_button {
  color: #1e5e96;
  transition: 0.2s all ease-in-out;
}

.down_show_button:hover {
  color: #ff0000;
  transition: 0.2s all ease-in-out;
}

.title_modal_new {
  text-align: center;
  color: #1e5e96;
  font-weight: 500;
  font-size: 1.5rem;
}

.modal_image {
  height: 15vh;
}

.barprocess-background {
  background-color: rgba(0, 0, 0, 0.07);
  height: 5px;
  width: 100%;
}

.barprocess {
  background-color: #1e5e96;
  border-radius: 10px;
  height: 5px;
  transition: all ease 0.3s;
}

.filter_tanggal {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.button_filter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.button_ref {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .detail_profile_wrapper {
    flex-wrap: wrap;
  }

  .title_modal_new {
    font-size: 1.2rem;
  }

  .detail_profile_paragraf {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    width: 100%;
  }

  .search_bar_lite {
    width: 200px;
  }

  .toggle_sini {
    top: 145px;
  }

  .toggle_map_mode {
    top: 185px;
  }
}

.vertical_top {
  vertical-align: top;
}

.margin_auto {
  margin: auto;
}

.pointer {
  cursor: pointer;
}

.add_sini {
  position: sticky;
  top: 100;
  right: 10;
}

.add_sini_button {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.table_sini {
  width: 100%;
}

.tabel_sini_back {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.enable_white_space {
  white-space: pre;
}

#bottom_panel {
  position: absolute;
  width: 96px;
  padding-top: 4px;
  width: 100%;
  bottom: 0;
  background-color: #f0f0ff;
}

#bottom_panel::after {
  background-color: #ccc;
  position: absolute;
  top: 0;
  height: 4px;
  width: 100%;
  cursor: ew-resize;
}

.edit_profile_row {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.button_filter_date {
  padding: 3px 13px 3px 13px;
  text-align: center;
  border-radius: 7px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  background-color: #0ca5eb;
  color: #ffffff;
}

.row {
  display: flex;
  gap: 10px;
}

.input_old {
  width: 100%;
}

.input_new {
  width: 100%;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #0ca5eb;
  outline: none;
  resize: none;
  background-color: #ffffff;
}

.input_new::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.input_square {
  width: 100%;
  border: none;
  border: 2px solid rgb(174, 176, 177);
  outline: none;
  resize: none;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 10px;
}

.option_content {
  padding: 5px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 5px;
}

.folder_expand {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

.nav_number_sini {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 40px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  padding: 10px;
  z-index: 9;
}

.box-content {
  padding: 1rem;
}

.button_sini_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.modal_download_sini {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}

.modal_assign {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  min-height: 70vh;
  position: relative;
}

.modal_assign_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.modal_assign_grup,
.modal_invite_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.modal_assign_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.modal_assign h1 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  position: absolute;
  top: 10px;
}

.assign_grup_row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #e5eefd;
  overflow: auto;
  border-radius: 20px;
  padding: 10px 20px;
  width: 90%;
  max-height: 65vh;
  margin-top: 60px;
}

.assign_row_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 0rem;
  left: 1rem;
}

.card_member_assign {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.card_member_assign select {
  width: 50%;
  background-color: transparent;
}

.card_member_assign p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.card_member_assign p:nth-child(2) {
  width: 30%;
}

.card_member_assign p:nth-child(3) {
  width: 35%;
}

.card_member_assign p:nth-child(4) {
  width: 15%;
}

.card_member_assign p:nth-child(5) {
  width: 10%;
}

.card_member_assign p:nth-child(6) {
  width: 10%;
}

.card_member_img {
  width: 35px;
  height: 30px;
  overflow: hidden;
  border-radius: 25px;
  border: 2px solid #fff;
}

.card_member_img img {
  width: 100%;
  height: 100%;
}

.register_group {
  width: 90%;
  padding: 1rem;
  border: 2px solid #000;
  border-radius: 10px;
  margin-top: 5rem;
}

.list_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.card_group {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #000;
  width: 200px;
  padding: 0 10px;
  height: 150px;
}

.card_group h3 {
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card_group_add {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #fff;
  padding: 1rem;
  width: 200px;
  background-color: #0ca5eb;
}

.card_group_add button {
  font-size: 1.1rem;
  color: #fff;
  margin-top: -5px;
}

.massages_modal_invite {
  text-align: center;
  color: #ff0000;
}

.button_back {
  width: 100%;
}

.info_status {
  position: absolute;
  bottom: -10px;
  font-size: 0.7rem;
  font-style: italic;
  font-weight: 700;
}

.row_table {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.buton_close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ff0000;
  font-weight: 800;
}

.button_cancel {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-weight: 700;
  color: #fff;
  font-size: 1.2rem;
  background-color: #ff0000;
}

.card_parent {
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
  gap: 5px 5px;
}

.card_list {
  list-style: none;
  border-radius: 5px;
  min-height: 30px;
}

.container_card {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 11px;
  padding-left: 11px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.container_upload {
  height: 200px;
  width: 100%;
  border-radius: 10px;
}

.modal_validasi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.modal_validasi p {
  margin: 0.5rem 0 1rem 0;
}

.modal_reject {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 1rem;
}

.modal_reject h2 {
  text-align: center;
}

.modal_reject_row {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.kotak_revisi,
.kotak_revisi_paid {
  width: 97%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 7px 14px;
  background-color: #e5eefd;
  border-radius: 10px;
}

.kotak_revisi h3,
.kotak_revisi p,
.row_suveyor_action p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.mapboxgl-popup-close-button {
  color: #fff;
  font-size: 1.5rem;
  padding: 0px 7px;
}

.row_surveyor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.row_surveyor_img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: gray;
  overflow: hidden;
}

.row_surveyor_img img {
  width: 100%;
  height: 100%;
}

.row_suveyor_action {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.row_suveyor_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  margin-top: -4px;
}

.small_circle {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 100%;
}

.circle_button {
  width: 70px;
  height: 70px;
  background: #0ca5eb;
  color: white;
  border-radius: 100%;
  border: 1px solid white;
}

.table_full_fix {
  width: 100%;
  table-layout: fixed;
}

.container_s {
  border-radius: 10px;
  padding: 5px;
}

.group_detail_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.page_group_detail {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title_detail_group {
  color: #1265ae;
  font-size: 4rem;
}

.subtitle_detail_group {
  color: #3a3a3a;
  font-size: 1rem;
  font-weight: 500;
}

.group_search {
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.group_search_row {
  width: 100%;
}

.group_search_input {
  width: 100%;
  background-color: transparent;
  border: 2px solid #cbcbd4;
  border-radius: 8px;
  padding: 3px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.group_search_input input {
  margin-bottom: 0;
  outline: none;
  border: none;
  width: 90%;
}

.group_search_input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.group_detail_member {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.group_member_top {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 2px solid #cbcbd4;
}

.group_member_top p {
  font-size: 1rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 5px 20px;
  margin-bottom: 0;
  color: #000;
}

.group_member_bottom {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card_group_detail {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  padding: 10px 20px;
  border: 2px solid #cbcbd4;
}

.card_group_name {
  width: 35%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.card_group_name_row_top {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card_group_name_row_top h3 {
  margin-bottom: 0;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 250px;
}

.card_group_name_row p {
  margin-bottom: 0;
  color: #575757;
  font-size: 1rem;
}

.card_group_email {
  width: 25%;
  display: flex;
  align-items: center;
}

.card_group_email p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card_group_role {
  width: 25%;
  display: flex;
  align-items: center;
}

.card_group_role select {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border: 2px solid #cbcbd4;
  border-radius: 8px;
}

.card_group_action {
  width: 17%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.card_search_group:hover {
  background-color: #9cd4f5;
}

.dropdown_in_table {
  margin-top: 7px;
  height: 100%;
  width: 100%;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  background: transparent;
  border-radius: 0;
}

.local_phone_in_table {
  height: 100%;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  background: transparent;
  border-radius: 0;
}

.parent_phone_number {
  display: grid;
  grid-template-columns: auto auto;
}

.phone_in_table {
  border: none;
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  box-sizing: content-box;
  height: 10px;
  background: transparent;
}

.time_in_table {
  border: none;
  padding: 0.5rem;
  margin: 0;
  display: inline-block;
  width: 100%;
  box-sizing: content-box;
  height: 10px;
  background: transparent;
}

.vertical_line {
  border-left: 2px solid silver;
  margin-left: -3px;
  top: 0;
}

.horizontal_line {
  height: 0px;
  border: none;
  border-bottom: 2px solid silver;
}

.title_sini {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 0 0;
}

.top_sini {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paragrah_title_sini {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 86%;
}

.sini_demo {
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 25px;
}

.sini_row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
}

.sini_scroll {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.5s all ease-in-out;
}

.sini_scroll_hide {
  width: 100%;
  height: 0;
  transition: 0.5s all ease-in-out;
}

.sini_scroll_hide * {
  display: none;
  width: 0;
  height: 0;
  border: none;
  border-radius: 0;
  transition: 0.5s all ease-in-out;
}

.sini_scroll_hide .card_znt_left,
.sini_scroll_hide .card_znt,
.sini_scroll_hide .sini_publikasi,
.sini_scroll_hide .sini_card_poi {
  display: none;
  width: 0;
  height: 0;
  border: none;
  background-color: transparent;
  transition: none;
}

.sini_scroll::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: transparent;
}

.sini_scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.sini_scroll::-webkit-scrollbar-thumb {
  background-color: #0ca5eb;
  border-radius: 10px;
  border: 3px solid #ffffff;
  background-clip: content-box;
}

.sini_small {
  height: 50px;
}

.card_sini {
  background-color: #ffffff;
  border: 2px solid #e5eefd;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 7px;
  gap: 10px;
  width: 95%;
  border-radius: 7px;
  height: 120px;
}

.card_sini_left {
  background-color: #0ca5eb;
  width: 50%;
  height: 120px;
  border-radius: 5px;
  position: relative;
}

.card_sini_left span {
  position: absolute;
  cursor: default;
  top: 5px;
  left: 5px;
  background-color: #ffffff;
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 10px;
  color: #0ca5eb;
}

.card_sini_right {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card_sini_right div:nth-child(1) {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card_sini_right div:nth-child(2) {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.creator_blog_sini {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.button_blog_sini {
  border: 2px solid #0ca5eb;
  border-radius: 25px;
  padding: 3px 20px;
  background-color: #0ca5eb;
  color: #ffffff;
}

.button_blog_sini:hover {
  background-color: transparent;
  color: #0ca5eb;
}

.sini_card_no_data {
  border: 2px solid #2f5e92;
  padding: 10px;
  border-radius: 7px;
  transition: 1s all ease;
}

.sini_card {
  border: 2px solid #0ca5eb;
  padding: 10px;
  border-radius: 7px;
  transition: 1s all ease;
  min-height: 55px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sini_card_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 55px;
  position: relative;
  z-index: 1;
}

.sini_change_radius {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.sini_change_radius h2 {
  color: #000000;
  margin-bottom: 2px;
  font-weight: 600;
}

.sini_card_top h2 {
  color: #2f5e92;
  margin-bottom: 2px;
  font-weight: 600;
}

.sini_loading {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.button_view_open,
.button_view_close,
.icon_plus_open,
.icon_plus_close {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease-in-out;
  position: relative;
}

.icon_plus_close {
  background-color: #ff0000;
}

.icon_plus_open {
  background-color: #009be4;
}

.icon_plus_close div,
.icon_plus_open div {
  width: 25px;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.5s all ease-in-out;
  position: absolute;
}

.icon_plus_close div:nth-child(1) {
  transform: rotate(220deg);
}

.icon_plus_close div:nth-child(2) {
  transform: rotate(-50deg);
}

.icon_plus_open div:nth-child(1) {
  transform: rotate(90deg);
}

.icon_plus_open div:nth-child(2) {
  transform: rotate(0);
}

.button_view_open div,
.button_view_close div {
  width: 15px;
  height: 5px;
  background-color: #2f5e92;
  border-radius: 5px;
  transition: 0.5s all ease-in-out;
  position: absolute;
}

.button_view_open div:nth-child(1) {
  transform: rotate(55deg);
  margin-left: -7px;
}

.button_view_open div:nth-child(2) {
  transform: rotate(-55deg);
  margin-right: -7px;
}

.button_view_close div:nth-child(1) {
  transform: rotate(130deg);
  margin-left: -7px;
}

.button_view_close div:nth-child(2) {
  transform: rotate(-130deg);
  margin-right: -7px;
}

.Isochrone_section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Isochrone_row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Isochrone_row h3 {
  width: 200px;
}

.Isochrone_input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  border-radius: 7px;
  overflow: hidden;
}

.Isochrone_input button {
  width: 100%;
  padding: 5px;
  font-weight: 600;
  font-size: 1rem;
  transition: 0.5s all ease-in-out;
}

.Isochrone_input p {
  width: 120px;
}

.isochrone_message {
  color: #ff0000;
  font-size: 0.7rem;
  font-weight: 600;
}

.button_eyes_open,
.button_eyes_close {
  width: 30px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.button_eyes_close div,
.button_eyes_open div {
  transition: 0.5s all ease-in-out;
}

.button_eyes_open div:nth-child(1),
.button_eyes_close div:nth-child(1) {
  width: 20px;
  height: 20px;
  background-color: #545454;
  border-radius: 75% 15%;
  position: relative;
  transform: rotate(45deg);
}

.button_eyes_open div:nth-child(2),
.button_eyes_close div:nth-child(2) {
  width: 7px;
  height: 7px;
  border-radius: 25px;
  background-color: #545454;
  border: 2px solid #fff;
  position: absolute;
}

.button_eyes_open div:nth-child(3) {
  width: 0;
  height: 3px;
  transform: rotate(35deg);
  background-color: #ff0000;
  position: absolute;
}

.button_eyes_close div:nth-child(3) {
  width: 30px;
  height: 3px;
  transform: rotate(35deg);
  background-color: #ff0000;
  position: absolute;
}

.sini_table {
  text-align: center;
  border-collapse: collapse;
  border: 2px solid #009be4;
}

.sini_table th {
  background-color: #009be4;
  margin-bottom: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
}

.sini_table td {
  padding: 5px 0;
}

.sini_table tr:nth-of-type(odd) {
  background-color: #fff;
}

.sini_table tr:nth-of-type(even) {
  background-color: #e5eefdff;
}

.sini_card_poi {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  border-radius: 5px;
  overflow: hidden;
}

.sini_card_poi_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.sini_card_poi_left h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.sini_card_poi_left p {
  font-size: 0.7rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}

.sini_card_poi_right {
  width: 80%;
  text-align: center;
}

.show_icon {
  width: 25px;
  height: 25px;
  transition: 0.3s all ease-in-out;
}

.hide_icon {
  width: 0px;
  height: 0px;
  transition: 0.3s all ease-in-out;
}

.sini_pie_chart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.sini_disaster_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  gap: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.card_znt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: 10px;
  min-height: 90px;
  padding: 7px 7px 7px 7px;
  position: relative;
  overflow: hidden;
}

.card_znt p {
  margin-bottom: 0;
}

.card_znt_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #009be4;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50px;
  width: 55px;
  height: 55px;
  color: #fff;
}

.card_znt_button {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 70px;
  right: 10px;
}

.card_znt_right {
  height: 100%;
}

.card_znt_right h1 {
  width: 250px;
  font-size: 1.5rem;
}

.card_znt_left h2 {
  font-size: 1.4rem;
}

.card_znt_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.card_znt_content h2 {
  font-size: 1rem;
}

.input_title_blog {
  margin: 0;
  width: 100%;
  max-height: 3px;
}

.sini_publikasi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.information_class {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.information_class_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_mini_information {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_overhide {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.toolbox_coordinates span {
  margin-right: 3px;
  padding: 3px 9px;
  border-radius: 10px;
  background-color: #0166cb;
  color: #fff;
}

.alert {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
}

.alert_popup {
  padding: 5px 20px;
  background-color: #fff;
  color: #0ca5eb;
  font-weight: 500;
  border: 2px solid #0ca5eb;
  border-radius: 7px;
}

.landing_auth {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100svw;
  height: 100svh;
}

.background_image_auth {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100svh;
  position: absolute;
}

.auth_content_left section {
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.v_line {
  border-left: 2px solid #aaa;
}

.auth_form {
  width: 100%;
}

@media screen and (max-width: 1045px) {
  .auth_content_left {
    width: 70%;
    gap: 10px;
  }

  .auth_content_left h1 {
    width: 100%;
    font-size: 35px;
    line-height: 77.5px;
  }

  .auth_content_left h2 {
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
  }
}

@media screen and (max-width: 768px) {
  .landing_auth {
    width: 100vw;
    height: 100%;
  }

  .auth_content {
    height: 100%;
    flex-direction: column;
    gap: 1rem;
  }

  .button_language {
    width: 30px;
    height: 30px;
  }
}

.search_center_content_hide,
.search_center_content_show,
.search_center_content_bottom {
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #fff;
  transition: 0.3s all ease-in-out;
  padding: 5px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
}

.search_center_content_bottom {
  width: 390px;
  height: 50px;
  padding: 10px 10px 5px 10px;
  text-align: left;
  justify-content: space-between;
  border-radius: 7px;
  top: 6rem;
  z-index: 1090;
  color: #343434;
}

.search_center_content_hide *,
.search_center_content_show * {
  transition: 0.5s all ease-in-out;
}

.search_center_button {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.search_center_content_hide .search_center_button {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_center_content_hide {
  top: 4%;
  width: 100px;
  height: 25px;
  padding: 5px 15px;
  border-radius: 50px;
}

.search_center_close {
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
}

.search_center_content_hide .search_center_close {
  width: 0;
  height: 0;
  padding: 0;
  display: none;
  background-color: transparent;
  font-size: 0;
  border: none;
}

.search_center_content_hide .search_center_input {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  cursor: pointer;
  z-index: 3;
}

.search_center_content_show {
  width: 400px;
  height: 40px;
  max-width: calc((100vw - 130px));
  padding: 5px 15px;
  border-radius: 10px;
  z-index: 3;
}

.search_center_input {
  border: none;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.menu_sidebar_view {
  z-index: 2;
  position: fixed;
  top: 9rem;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.menu_sidebar_view section {
  background-color: #fff;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.094);
  border-radius: 5px;
  width: 25px;
  height: 25px;
  padding: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu_sidebar_view img {
  width: 23px;
  height: 23px;
}

.menu_sidebar_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.094);
  border-radius: 5px;
  background-color: #fff;
}

.menu_sidebar_data button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  transition: 0.3s all ease-in-out;
  background-color: #fff;
}

.menu_sidebar_data p {
  font-size: 0rem;
  position: absolute;
  transition: 0.2s all ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 solid #fff;
}

.menu_sidebar_data button:hover p {
  font-size: 13px;
  font-weight: 500;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  margin-right: -135px;
  margin-top: 5px;
  height: 27px;
  width: 100px;
  transition: 0.2s all ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #009be4;
}

.menu_sidebar_data button:hover {
  margin-left: -205px;
}

.menu_sidebar_data button:nth-child(1):hover,
.menu_sidebar_data button:nth-child(2):hover,
.menu_sidebar_data button:nth-child(3):hover {
  border-radius: 5px 0 0 5px;
}

.menu_sidebar_data button:nth-child(1) {
  border-radius: 5px 5px 0 0;
}

.menu_sidebar_data button:nth-child(2) {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.menu_sidebar_data button:nth-child(3) {
  border-radius: 0 0 5px 5px;
}

.checklist_active {
  display: inline-block;
  background-color: rgb(125, 194, 61);
  color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid rgb(125, 194, 61);
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.checklist_not {
  display: inline-block;
  background-color: #fff;
  color: #000;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid rgb(113, 113, 113);
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.checklist_active::before {
  content: "v";
}

.checklist_not::before {
  content: "-";
}

.simplemap_header {
  display: flex;
  flex-direction: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
}

.simplemap_title {
  font-size: 16px;
  color: #1265ae;
  font-weight: 600;
  margin: 5px 0;
}

.simplemap_row {
  width: 100%;
  padding: 1rem 0;
}

.simplemap_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.simplemap_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  background-color: #62cdf2;
  color: #fff;
}

.simplemap_card_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.simplemap_card_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.simplemap_card_title p {
  margin-top: 5px;
  font-size: 1.1rem;
  text-transform: capitalize;
}

.simplemap_card_bottom {
  width: 100%;
}

.simplemap_card_bottom select {
  font-size: 14px;
  padding: 3px;
  border-radius: 10px;
  border: 2px solid #a6a6a6;
}

.simple_value_tabel {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.simple_value_tabel p:nth-child(1) {
  text-align: left;
  width: 150px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.simple_content_row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 5px;
}

.simplemap_content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.simplemap_content_top h2,
.simplemap_content_top button {
  font-size: 1.3rem;
  font-weight: 600;
  color: #2f5e92;
}

.simplemap_card {
  border: 2px solid #0ca5eb;
  padding: 10px;
  border-radius: 7px;
  transition: 1s all ease;
  min-height: 25px;
  margin-bottom: 10px;
}

.simplemap_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  text-align: center;
  font-weight: 700;
  color: #fff;
}

.page_pdf {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.simple_map_language {
  position: fixed;
  z-index: 999;
  top: 9rem;
  right: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 4px 4px 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_hide_fields_parent {
  position: relative;
}

.popup_hide_fields_button {
  cursor: pointer;
  color: "#5096db";
  display: "flex";
  align-items: "center";
  gap: "5px";
  text-transform: "uppercase";
}

.popup_hide_fields {
  position: absolute;
  z-index: 50;
  background-color: #fff;
  min-width: 250px;
  bottom: 110%;
  left: 0;
  margin-bottom: 0.125rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 8px;
  max-height: 500px;
}

.popup_hide_fields_scroll {
  overflow-y: auto;
  max-height: 450px;
}

.popup_hide_fields_content {
  height: 30px;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.a4 {
  margin-left: 7.125pt;
  font-size: 10px;
  width: 100%;
}

.cilicis_pdf {
  page-break-inside: auto;
}

.empty_header_cilicis {
  height: 120px;
}

.empty_footer_cilicis {
  height: 50px;
}

.page_header_cilicis {
  position: fixed;
  top: 0;
  z-index: 9;
  page-break-before: always;
}

.page_footer_cilicis {
  position: fixed;
  z-index: 9;
  bottom: 0;
  height: 50px;
  page-break-after: always;
}

.cilicis_pdf > table > tbody > tr > td > p {
  font-size: 14px;
}

.cilicis_pdf > table > tbody > tr > td > ol > li {
  font-size: 14px;
  font-weight: bold;
  page-break-inside: avoid;
  page-break-after: auto;
}

.cilicis_pdf > table > tbody > tr > td > ol > li > p > a {
  font-weight: normal;
}

.cilicis_pdf > table > tbody > tr > td > table > tbody > tr > td {
  font-size: 14px;
}

.cilicis_pdf > table > tbody > tr > td > table > thead > tr > td {
  font-size: 14px;
  font-weight: bold;
}

.cilicis_pdf > table > tbody > tr > td > table > tbody > tr > td > div {
  border: 2px solid;
  height: 30px;
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.text_pdf {
  padding-right: 68pt;
  text-indent: 0pt;
  line-height: 185%;
  text-align: left;
}

.title_pdf {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  font-weight: 700;
}

.subtitle_pdf {
  font-family: "Times New Roman", Times, serif;
  font-size: 11px;
  font-weight: 700;
  text-align: left;
}

.table_pdf {
  border: 2px solid;
  font-family: "Roboto", sans-serif;
  border-collapse: collapse;
  margin-left: 7.125pt;
  font-size: 10px;
  width: 100%;
}

.table_pdf.no_margin {
  margin-left: 0;
}

.table_no_bottom_border_pdf {
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  font-family: "Roboto", sans-serif;
  border-collapse: collapse;
  margin-left: 7.125pt;
  font-size: 10px;
  width: 100%;
}

.table_pdf > tbody > tr {
  height: 16pt;
}

.table_pdf > thead > tr > td {
  padding: 0 2px 0 2px;
}

.table_pdf > thead > tr > td.no_padding {
  padding: 0 0 0 0;
}

.table_pdf > tbody > tr > td.no_padding {
  padding: 0 0 0 0;
}

.table_pdf.no_border {
  border: none;
}

.table_pdf > tbody > tr > td {
  padding: 0 2px 0 2px;
}

.table_pdf > tbody > tr > td.quarter {
  width: 25%;
}

.tr_border_top_pdf {
  border-top: 1pt solid;
}

.tr_border_bottom_pdf {
  border-bottom: 1pt solid;
}

.table_pdf.side_border > thead > tr > td {
  border-bottom: 1pt solid;
  border-left: 1px solid;
  text-align: center;
}

.table_pdf.side_border > thead > tr > td.no_left_border:first-child {
  border-left: none;
}

.table_pdf.side_border > tbody > tr > td:first-child {
  border-left: none;
}

.table_pdf.side_border > tbody > tr > td {
  border-left: 1px solid;
}

.table_pdf.side_border > tbody > tr {
  border-bottom: 1px solid;
}

.table_pdf.C9 > tbody > tr > td:first-child {
  width: 83.3333%;
}

.table_pdf.C9 > tbody > tr > td {
  width: 16.6666%;
}

.table_pdf.c11 > tbody > tr > td:first-child {
  width: 30%;
}

.table_pdf.c11 > tbody > tr > td {
  width: 70%;
}

.table_pdf.c13 > thead > tr {
  min-height: 30px;
  height: 30px;
  max-height: 30px;
}

.table_pdf.c13 > tbody > tr {
  min-height: 30px;
  height: 30px;
  max-height: 30px;
}

.table_pdf.c13 > tbody > tr.last_child {
  min-height: 100px;
  height: 100px;
  max-height: 100px;
}

.table_pdf.table_box_pdf > tbody > tr > td > p.highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
}

.table_pdf.table_box_pdf > tbody > tr > td > p.header {
  font-size: 10px;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
}

tr.sebagai > td {
  width: 25%;
  text-align: center;
}

tr.nama_terang > td > div {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.margin_pdf {
  margin: 30px 50px 30px 30px;
  padding: 30px 0 0 0 0;
}

.break_before_pdf {
  margin-top: 30px;
  page-break-before: always;
}

.legend_close {
  z-index: 1;
  position: fixed;
  bottom: 90px;
  right: 10px;
  background-color: #fff;
  padding: 4px;
  border-radius: 10px;
}

.arrow_up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #02044a;
}

.arrow_down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #02044a;
}

.arrow_right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #02044a;
}

.arrow_left {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #02044a;
}

.max_hight {
  min-height: 80vh;
}

.max_height {
  min-height: calc(100vh - 170px);
}

.select_poi_insight_child {
  max-height: 90px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.select_poi_insight_parent:hover .select_poi_insight_child {
  max-height: 100vh;
}

.inline {
  display: inline-block;
  vertical-align: top;
}

.inline_center {
  display: inline-block;
  vertical-align: middle;
}

.inline_bottom {
  display: inline-block;
  vertical-align: bottom;
}

.row_qouta_price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row_qouta_price {
  padding: 1rem;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  cursor: pointer;
}

.card_qouta_price {
  border: 2px solid #ababab;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card_qouta_price.selected {
  border: 3px solid #007bd8;
}

.card_qouta_price ul {
  padding: 0 20px 0 20px;
  list-style-type: none;
  font-size: x-large;
}

.card_qouta_price ul li {
  margin: 10px 0 10px 0;
  list-style-type: none;
  font-size: small;
  display: flex;
}

.card_qouta_price ul li span {
  margin-right: 10px;
  background-color: #007bff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_qouta_price ul li span img {
  height: 15px;
  width: 15px;
}

.card_qouta_price section {
  padding: 10px 0 10px 0;
  font-weight: 300;
  font-size: 30px;
  display: flex;
  justify-content: center;
}

.card_qouta_price footer {
  list-style-type: none;
  font-size: x-large;
  padding: 0 20px 20px 20px;
}

.card_qouta_price footer button {
  background-color: white;
  color: #007bff;
  border: 2px solid #007bd8;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s, color 0.3s;
}

.card_qouta_price footer button.selected {
  background-color: #007bd8;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

.card_qouta_price footer button.selected {
  background-color: #007bd8;
  color: #fff;
}

.button_box {
  display: inline-block;
  text-align: center;
  width: 60px;
  height: 60px;
  vertical-align: top;
  margin: 5px;
  padding: 3px;
  border-radius: 10px;
}

.button_active {
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px inset;
  background-color: #c0e4ff;
  cursor: pointer;
}

.button_inactive {
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #ffffff;
  cursor: pointer;
}

.button_inactive:hover {
  transform: scale(1.1);
}

.button_disable {
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #c4c4c4;
  cursor: default;
}

.table_padding th,
td {
  padding: 3px;
}

.max_two_lines_big {
  display: inline-block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.2em;
  line-height: 1.1em;
}

.max_one_lines {
  display: inline-block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.3em;
  line-height: 1.2em;
}

.max_two_lines {
  display: inline-block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.2em;
  line-height: 1.8em;
}

.max_four_lines {
  display: inline-block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 4.8em;
  line-height: 1.2em;
}

.max_five_lines {
  display: inline-block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 6em;
  line-height: 1.2em;
}

.datepicker-native-button {
  position: relative;
  padding: 2px;
  width: 20px;
  height: 20px;
}

.datepicker-native-button-input {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  opacity: 0;
}

.datepicker-native-button-input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}

.floating_close_button_table {
  position: absolute;
  z-index: 5;
  background: white;
  height: 25px;
  width: 40px;
  top: -25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #a32f27;
}

.floating_close_button_sidebar_form_edit {
  position: absolute;
  z-index: 5;
  height: 25px;
  width: 25px;
  top: 3px;
  right: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #a32f27;
}

.scroll_container_sidebar {
  overflow-y: auto;
  height: calc(100vh - 60px);
  margin-top: 30px;
}

.pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 15px;
}

.modal_content {
  width: 90%;
}

.slider {
  padding: 0;
}

.sidebar_dashboard_layout {
  display: grid;
  grid-template-rows: 350px 140px auto;
}

.recovery_card {
  width: 294px;
  height: 143px;
  border-radius: 10px;
  border: 2px solid #a2b3bf;
  background: #ffffff;
  display: inline-block;
  margin: 10px;
  box-shadow: 2px 2px 12px #a6a6a6, -5px -5px 12px #ffffff;
}

.title_recovery_page {
  font-size: 64px;
  font-weight: 600;
  color: #2c65a9;
}

.title_recovery_card {
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  max-width: 700px;
  min-height: 20px;
}

.recovery_list {
  height: 70px;
  border-radius: 10px;
  border: 2px solid #a2b3bf;
  background: #ffffff;
  display: block;
  margin: 10px;
  box-shadow: 2px 2px 5px #a6a6a6, -2px -2px 5px #ffffff;
}

.underline_input {
  width: 100%;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #0ca5eb;
  outline: none;
  resize: none;
  height: 30px;
}

@media (min-width: 320px) {
  .modal_content {
    width: 80%;
  }
}

@media (min-width: 800px) {
  .modal_content {
    width: 700px;
  }
}

.width_40 {
  max-width: 40px;
}

.middle {
  vertical-align: middle;
  display: inline-block;
}

.draggable {
  max-width: 200px;
  border: 2px solid #dedede;
}

.draggable-panel {
  padding: 10px;
}

.draggable-content {
  padding: 15px;
}

.sini_full_parent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 105px);
  position: relative;
  overflow: hidden;
}

.sini_full_header {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  background: #fff;
  z-index: 1;
}

.sini_full_scroll_area {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 150px;
  padding-right: 10px;
}

.ai_input_text_area {
  background-color: transparent;
  padding-bottom: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
  width: 100%;
  flex-shrink: 0;
}

.ai_input_text_area_sidebar {
  background-color: #fff;
  padding-bottom: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
  width: 100%;
  flex-shrink: 0;
}

.formula {
  display: inline-block;
  white-space: nowrap;
  overflow-x: auto;
  background-color: #0172fe;
  color: #fff;
  font-size: 10px;
  margin-bottom: 5px;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
}

.markdown-content > * {
  margin-bottom: 1px;
}

.markdown-content ul,
.markdown-content ol {
  margin-left: 20px;
}

.ai_page_container {
  height: 100%;
  display: flex;
}

.ai_page_left_sidebar {
  box-sizing: border-box;
  width: 277px;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 15px;
}

.ai_page_chat_list {
  box-sizing: border-box;
  flex: 1;
  background-color: rgba(230, 243, 252, 1);
  border-radius: 15px;
  padding: 10px;
  width: calc(100% - 300px);
  height: 100%;
  position: relative;
}

.max_width_300 {
  max-width: 300px;
}

.button_flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.item_right {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  right: 0px;
  top: 0;
  padding-right: 20px;
  z-index: 1;
  background: linear-gradient(to right, transparent, #fff, #fff);
  text-align: right;
  width: 30px;
  height: 100%;
}

.button_flex:hover .item_right {
  opacity: 1;
}

.item_right.show {
  opacity: 1;
}

.parent_half {
  width: 100%;
  display: flex;
}

.left_half,
.right_half {
  flex: 1;
  box-sizing: border-box;
}

.pie_chart {
  max-width: 150px;
}

.top_aligned_cell td {
  vertical-align: top;
}

.hover_grey tr:hover {
  background-color: rgb(240, 246, 246);
}

#people_spending_parent {
  position: relative;
  transition: right 0.4s ease-out;
}

#people_spending_parent.slide-in {
  right: 0;
}

#landvalue_parent {
  position: relative;
  transition: right 0.4s ease-out;
}

#landvalue_parent.slide-in {
  right: 0;
}

#flood_parent {
  position: relative;
  transition: right 0.4s ease-out;
}

#flood_parent.slide-in {
  right: 0;
}

#poi_parent {
  position: relative;
  transition: right 0.4s ease-out;
}

#poi_parent.slide-in {
  right: 0;
}

#rdtr_parent {
  position: relative;
  transition: right 0.4s ease-out;
}

#rdtr_parent.slide-in {
  right: 0;
}

.sini_container_animation {
  display: block;
  position: relative;
  width: 25px;
  max-width: 75vw;
  height: 25px;
  max-height: 75vw;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sini_shape_animation {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #21a4f6;
  filter: blur(9px);
  animation: sini_animation_keyframes 8s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

.sini_shape_animation:nth-child(2) {
  background: #a0d061;
  animation-delay: 2s;
}

.sini_shape_animation:nth-child(3) {
  background: #1a649d;
  animation-delay: 4s;
}

@keyframes sini_animation_keyframes {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(15px, 15px) rotate(90deg);
  }

  50% {
    transform: translate(21px, 0) rotate(180deg);
  }

  75% {
    transform: translate(15px, -15px) rotate(270deg);
  }

  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

.scale_range {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.scale_range_number {
  position: relative;
  top: -10px;
  font-size: 12px;
}

.code_editor {
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  tab-size: 2;
  font-optical-sizing: auto;
  font-style: normal;
  font-variant-numeric: tabular-nums;
}

.poi_marker_parent {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.poi_marker_parent_select {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1 !important;
  transform: translateZ(0);
}

.poi_marker_box_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
}

.poi_marker_pin {
  width: 1px;
  height: 30px;
  background-color: black;
  position: relative;
}

.tabs_pill {
  width: 400px;
  height: 50px;
  background-color: #f4f4f4;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 5px;
}

.tabs_pill > button {
  width: 120px;
  height: 40px;
  border-radius: 50px;
  border: 0px;
  color: #afafaf;
}

.tabs_pill > button:hover {
  transform: scale(1.1);
  color: black;
}

.tab_slider {
  display: inline-block;
  width: 30px;
  height: 4px;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
}

.tabs_pill > button.active {
  background-color: #006aba;
  color: #ffffff;
}

.card_license {
  display: flex;
  border: 3px solid #ababab;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  max-width: 80vw;
  vertical-align: top;
  border-radius: 30px;
  padding: 25px;
  cursor: pointer;
}

.card_license:hover {
  transform: scale(1.05);
}

.card_license.active_card {
  border: 3px solid #006aba;
}

.card_license > button,
.card_license > a {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: 3px solid #ababab;
  color: #ababab;
  text-align: center;
}

.card_license.active_card > button,
.card_license.active_card > a {
  background: #006aba;
  color: #ffffff;
  border: 3px solid #006aba;
}

.card_license.active_card > button:hover,
.card_license.active_card > a:hover {
  transform: scale(1.1);
}

.redeem_field {
  max-width: 500px;
  min-width: 70%;
  border: 3px dashed #006aba;
  height: 60px;
  padding: 0 20px 0 20px;
}

.basemap_copy {
  width: 600px;
  overflow-x: auto;
  white-space: nowrap;
  border: 2px solid #aaa;
  background-color: #ddd;
  padding: 5px;
  border-radius: 5px;
}

.basemap_copy_box {
  display: flex;
  gap: 3px;
  width: 100%;
  text-align: left;
  flex-wrap: nowrap;
}

.basemap_styles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  margin-bottom: 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  text-align: center;
}

.otp-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.otp-container p {
  font-size: 14px;
  color: #555;
  margin: 4px 0;
}

.email {
  font-weight: bold;
}

.otp-inputs {
  display: flex;
  gap: 10px;
  margin: 16px 0;
}

.otp-input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 2px solid grey;
  border-radius: 3px;
}

.otp-button {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 16px;
}

.otp-button:hover {
  background-color: #333;
}

.resend-text {
  margin-top: 12px;
  font-size: 14px;
  color: #555;
}

.resend-link {
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.summary_sini {
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .basemap_copy {
    width: fit-content;
  }

  .basemap_styles {
    flex-direction: column;
  }
}

.user-unit {
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.user-unit.non-member {
  cursor: pointer;
}

.user-unit.member {
  cursor: default;
}

.user-photo {
  background-color: #c1c1c1;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #2196f3;
  display: inline-block;
  background-size: cover;
  background-position: center;
}

.user-name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 12px;
}

.badge-member {
  margin-left: 5px;
  background-color: #c1c1c1;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  background-position: center;
}
