@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map_controls_wrapper_bi {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 3;
}

.map_controls_wrapper {
  position: fixed;
  right: 5px;
  top: 5px;
  z-index: 3;
}

.map_controls_background {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.map_controls_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
}

.map_button_draw {
  width: 15px;
  height: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease;
}

.map_button_draw:active {
  transform: scale(1.5);
}

.separator {
  width: 80%;
  height: 2px;
  background-color: #ccc;
  margin: 8px 0;
}

.icon_rotate {
  animation: rotate 3s linear infinite;
}

.icon_scale {
  transition: transform 1s ease-in;
  transform: scale(1.3);
}

.icon_reset {
  transition: transform 0.1s ease-out;
  transform: scale(1);
}
