/* CHAT BOX */

.chat_main {
  position: fixed;
  bottom: 100px;
  right: 50px;

  max-width: 80vw;
  max-height: 80vh;
  width: 350px;
  height: 900px;

  z-index: 30;

  background-color: #e0eafc;
  background-image: linear-gradient(to bottom, #e0eafc, #cfdef3);
  -webkit-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  -moz-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.071);
  overflow-y: scroll;
}

.chat_title {
  padding: 10px;
  width: calc(100% - 20px);
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  position: sticky;
  top: 0px;
  max-height: 35px;
  overflow: hidden;
}

.chat_conversation {
  min-height: 350px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 70px;
  margin-top: 30px;
}

.chat_writing {
  position: fixed;
  bottom: 100px;
  right: 50px;

  max-width: 80vw;
  width: 350px;
  height: 200px;

  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff;

  padding-bottom: 20px;
  padding-top: 10px;
}

.chat_bubble {
  clear: both;
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: auto;
  padding: 3px;
  background: white;
  border-radius: 7px;
  margin: 5px 2px;
  display: inline-block;
  z-index: -1;
  padding-left: 15px;
  padding-right: 15px;
}

.chat_right {
  text-align: right;
}

.chat_left {
  text-align: left;
}

.chat_user {
  background-color: #62cdf2ff;
  background-image: linear-gradient(to right, #62cdf2ff, rgb(0, 191, 255));
  color: #ffffff;
}

.chat_member {
  background-color: #ffffff;
}

.chat_user_color {
  color: #ffffff;
}

.chat_other_color {
  color: #2bc0e4;
}

.chat_name {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 3px;
}

.chat_text {
  font-size: 13px;
}
