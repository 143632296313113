.bi_sidebar_css {
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: #000;
  color: #fff;
  height: 100vh;
  width: 350px;
  z-index: 10;
  padding: 10px;
}

.bi_input_css {
  background-color: #000;
  color: #fff;
  font-size: 13px;
  border: 1px solid #2649ef;
  height: calc(100vh - 100px);
}
